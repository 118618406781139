 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import styled from '@emotion/styled'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import useForceUpdate from '~/hooks/useForceUpdate'
import useAtmosphere from '../hooks/useAtmosphere'
import useBreakpoint from '../hooks/useBreakpoint'
import useEventCallback from '../hooks/useEventCallback'
import usePortal from '../hooks/usePortal'
import useRouter from '../hooks/useRouter'
import useTransition from '../hooks/useTransition'
import {Breakpoint, NavSidebar, ZIndex} from '../types/constEnums'
import clientTempId from '../utils/relay/clientTempId'
import SnackbarMessage from './SnackbarMessage'

const MAX_SNACKS = 1

const Modal = styled('div')(
  ({hasSidebar, isDesktop}) => ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'flex-end',
    left: 0,
    padding: 8,
    paddingBottom: hasSidebar ? 64 : 8,
    position: 'fixed',
    top: 0,
    width: hasSidebar && isDesktop ? `calc(100% + ${NavSidebar.WIDTH}px)` : '100%',
    pointerEvents: 'none',
    zIndex: ZIndex.SNACKBAR
  })
)

 



















const Snackbar = React.memo(() => {
  const snackQueueRef = useRef([])
  const activeSnacksRef = useRef([])
  const forceUpdate = useForceUpdate()
  const atmosphere = useAtmosphere()
  const {openPortal, terminatePortal, portal} = usePortal({id: 'snackbar', noClose: true})
  const {location} = useRouter()
  const hasSidebar =
    location.pathname.startsWith('/meet/') || !!location.pathname.match(/\/meet\/.*\/responses/g)
  const isDesktop = useBreakpoint(Breakpoint.SIDEBAR_LEFT)
  const transitionChildren = useTransition(activeSnacksRef.current)
  // used to ensure the snack isn't dismissed when the cursor is on it
  const hoveredSnackRef = useRef(null)
  const dismissOnLeaveRef = useRef()

  const filterSnacks = useEventCallback((removeFn) => {
    const filterFn = (snack) => !removeFn(snack)
    snackQueueRef.current = snackQueueRef.current.filter(filterFn)
    const nextSnacks = activeSnacksRef.current.filter(filterFn)
    if (nextSnacks.length !== activeSnacksRef.current.length) {
      activeSnacksRef.current = nextSnacks
      forceUpdate()
    }
  })

  const dismissSnack = useEventCallback((snackToDismiss) => {
    _optionalChain([snackToDismiss, 'access', _ => _.onDismiss, 'optionalCall', _2 => _2()])
    filterSnacks((snack) => snack === snackToDismiss)
  })

  const showSnack = useEventCallback((snack) => {
    activeSnacksRef.current = [...activeSnacksRef.current, snack]
    if (snack.autoDismiss !== 0) {
      setTimeout(() => {
        if (hoveredSnackRef.current === snack) {
          dismissOnLeaveRef.current = snack
        } else {
          dismissSnack(snack)
        }
      }, snack.autoDismiss * 1000)
    }
    forceUpdate()
    _optionalChain([snack, 'access', _3 => _3.onShow, 'optionalCall', _4 => _4()])
  })

  const onMouseEnter = (snack) => () => {
    hoveredSnackRef.current = snack
  }

  const onMouseLeave = () => {
    if (dismissOnLeaveRef.current) {
      dismissSnack(dismissOnLeaveRef.current)
      dismissOnLeaveRef.current = undefined
    }
    hoveredSnackRef.current = null
  }

  const handleAdd = useEventCallback((snack) => {
    const dupeFilter = ({key}) => key === snack.key
    const snackInQueue = snackQueueRef.current.find(dupeFilter)
    const snackIsActive = activeSnacksRef.current.find(dupeFilter)
    if (snackInQueue || snackIsActive) return
    // This is temporary until these errors stop showing up in sentry
    if (typeof snack.message !== 'string') {
      console.error(`Bad snack message: ${snack.key}`)
      if (snack.message.message) {
        snack.message = snack.message.message
      } else {
        return
      }
    }
    const keyedSnack = {key: clientTempId(), ...snack}
    if (transitionChildren.length < MAX_SNACKS) {
      showSnack(keyedSnack)
    } else {
      snackQueueRef.current.push(keyedSnack)
    }
  })

  // handle events
  useEffect(() => {
    atmosphere.eventEmitter.on('addSnackbar', handleAdd)
    atmosphere.eventEmitter.on('removeSnackbar', filterSnacks)
    return () => {
      atmosphere.eventEmitter.off('addSnackbar', handleAdd)
      atmosphere.eventEmitter.off('removeSnackbar', filterSnacks)
    }
  }, [])

  // handle portal
  useLayoutEffect(() => {
    if (transitionChildren.length === 0 && snackQueueRef.current.length === 0) {
      terminatePortal()
    } else {
      openPortal()
    }
  }, [openPortal, terminatePortal, transitionChildren])

  // handle queue
  useEffect(() => {
    if (snackQueueRef.current.length > 0 && transitionChildren.length < MAX_SNACKS) {
      showSnack(snackQueueRef.current.shift())
    }
  }, [showSnack, transitionChildren])

  return portal(
    React.createElement(Modal, { hasSidebar: hasSidebar, isDesktop: isDesktop,}
      , transitionChildren.map(({child, onTransitionEnd, status}) => {
        const dismiss = () => {
          if (child.noDismissOnClick) return
          dismissSnack(child)
          _optionalChain([child, 'access', _5 => _5.onManualDismiss, 'optionalCall', _6 => _6()])
        }
        return (
          React.createElement(SnackbarMessage, {
            key: child.key,
            message: child.message,
            action: child.action,
            secondaryAction: child.secondaryAction,
            status: status,
            onTransitionEnd: onTransitionEnd,
            dismissSnack: dismiss,
            onMouseEnter: onMouseEnter(child),
            onMouseLeave: onMouseLeave,
            showDismissButton: child.showDismissButton,}
          )
        )
      })
    )
  )
})

export default Snackbar
