import styled from '@emotion/styled'
import React, { Suspense, useEffect} from 'react'
import ErrorBoundary from '../components/ErrorBoundary'
import LoadingComponent from '../components/LoadingComponent/LoadingComponent'
import ModalError from '../components/ModalError'
import {DECELERATE} from '../styles/animation'
import {PALETTE} from '../styles/paletteV3'
import {Duration, ZIndex} from '../types/constEnums'

import {PortalStatus} from './usePortal'

const ModalBlock = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  // no margins or paddings since they could force it too low & cause a scrollbar to appear
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: ZIndex.DIALOG,
  overflow: 'scroll'
})

const backdropStyles = {
  [PortalStatus.Entering]: {
    opacity: 1,
    transition: `opacity ${Duration.MODAL_OPEN}ms ${DECELERATE}`
  },
  [PortalStatus.Exiting]: {
    opacity: 0,
    transition: `opacity ${Duration.PORTAL_CLOSE}ms ${DECELERATE}`
  },
  [PortalStatus.Mounted]: {
    opacity: 0
  }
} 

const modalStyles = {
  [PortalStatus.Mounted]: {
    opacity: 0,
    transform: 'translateY(32px)'
  },
  [PortalStatus.Entering]: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: `transform ${Duration.MODAL_OPEN}ms ${DECELERATE}, opacity ${Duration.MODAL_OPEN}ms ${DECELERATE}`
  },
  [PortalStatus.Entered]: {
    // wipe transform so it plays nicely with react-beautiful-dnd
  },
  [PortalStatus.Exiting]: {
    opacity: 0,
    transform: 'translateY(-32px)',
    transition: `transform ${Duration.PORTAL_CLOSE}ms ${DECELERATE}, opacity ${Duration.PORTAL_CLOSE}ms ${DECELERATE}`
  }
} 

const Scrim = styled('div')



(({background, portalStatus}) => ({
  background,
  height: '100%',
  position: 'fixed',
  width: '100%',
  ...backdropStyles[portalStatus ]
}))

// Animating a blur is REALLY expensive, so we blur on the branch above to keep things flowing
const BlurredScrim = styled('div')(({backdropFilter}) => ({
  height: '100%',
  position: 'fixed',
  width: '100%',
  backdropFilter
}))

const ModalContents = styled('div')(({portalStatus}) => ({
  display: 'flex',
  flex: '0 1 auto',
  flexDirection: 'column',
  position: 'relative',
  marginTop: 'auto',
  marginBottom: 'auto',
  ...modalStyles[portalStatus ]
}))

const useModalPortal = (
  portal,
  targetRef,
  portalStatus,
  setPortalStatus,
  loadingDelayRef,
  closePortal,
  background,
  backdropFilter
) => {
  useEffect(() => {
    let isMounted = true
    if (portalStatus === PortalStatus.Entering) {
      setTimeout(() => {
        if (isMounted) {
          setPortalStatus(PortalStatus.Entered)
        }
      }, Duration.MODAL_OPEN)
    }
    return () => {
      isMounted = false
    }
  }, [portalStatus, setPortalStatus])
  return (reactEl) => {
    return portal(
      React.createElement(ModalBlock, { ref: targetRef ,}
        , React.createElement(BlurredScrim, { backdropFilter: backdropFilter,}
          , React.createElement(Scrim, {
            onClick: closePortal,
            background: background || PALETTE.SLATE_700_30,
            portalStatus: portalStatus,}
          )
        )
        , React.createElement(ModalContents, { portalStatus: portalStatus,}
          , React.createElement(ErrorBoundary, {
            fallback: (error, eventId) => (
              React.createElement(ModalError, { error: error, portalStatus: portalStatus, eventId: eventId,} )
            ),}

            , React.createElement(Suspense, {
              fallback: 
                React.createElement(LoadingComponent, {
                  loadingDelayRef: loadingDelayRef,
                  spinnerSize: 24,
                  height: 24,
                  showAfter: 0,}
                )
              ,}

              , reactEl
            )
          )
        )
      )
    )
  }
}

export default useModalPortal
