import styled from '@emotion/styled'
import React from 'react'
import PrimaryButton from '~/components/PrimaryButton'
import ReportErrorFeedback from '~/components/ReportErrorFeedback'
import useModal from '~/hooks/useModal'
import {isOldBrowserError} from '~/utils/isOldBrowserError'

const isNotFoundError = (error) => error.name === 'NotFoundError'

const ErrorBlock = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '100%'
})

const Button = styled(PrimaryButton)({
  marginTop: 8
})

const Link = styled('a')({
  color: 'inherit'
})






const ErrorComponent = (props) => {
  const {error, eventId} = props
  console.error(error)
  const {modalPortal, openPortal, closePortal} = useModal()

  if (isNotFoundError(error)) {
    return (
      React.createElement(ErrorBlock, null
        , React.createElement('div', null, "Oh no! Seems like you're using Google Translate or a similar extension, which has a bug in it that can crash apps like ours."


        )
        , React.createElement('div', null, "If this continues, please disable the extension"      )
        , React.createElement(Button, { onClick: () => window.location.reload(),}, "Refresh the page"  )
      )
    )
  }

  const isOldBrowserErr = isOldBrowserError(error.message)
  if (isOldBrowserErr) {
    const url = 'https://browser-update.org/update-browser.html'
    return (
      React.createElement(ErrorBlock, null
        , "Oh no! You've found a bug because the browser you're using needs to be updated."
        , React.createElement(Button, null
          , React.createElement(Link, { href: url, target: "_blank", rel: "noreferrer",}, "Update now"

          )
        )
      )
    )
  }
  return (
    React.createElement(ErrorBlock, null
      , 'An error has occurred! We’ve alerted the developers. Try refreshing the page'
      , eventId && React.createElement(Button, { onClick: openPortal,}, "Report Feedback" )
      , modalPortal(React.createElement(ReportErrorFeedback, { closePortal: closePortal, eventId: eventId,} ))
    )
  )
}

export default ErrorComponent
