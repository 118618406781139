import * as Tooltip from '@radix-ui/react-tooltip'
import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Action from './components/Action/Action'
import AtmosphereProvider from './components/AtmosphereProvider/AtmosphereProvider'
import './styles/theme/global.css'

export default function Root() {
  return (
    React.createElement(AtmosphereProvider, null
      , React.createElement(Router, null
        , React.createElement(Tooltip.Provider, null
          , React.createElement(Action, null )
        )
      )
    )
  )
}
