import styled from '@emotion/styled'
import React, {forwardRef, useState} from 'react'

import ui from '../styles/ui'
import PlainButton, {} from './PlainButton/PlainButton'










const ButtonRoot = styled(PlainButton)(({
  disabled,
  elevationResting,
  elevationHovered,
  elevationPressed,
  pressedDown,
  size
}) => {
  return {
    // size is easy to override, it adds: fontSize, lineHeight, padding
    ...(ui.buttonSizeStyles[size] ),
    alignItems: 'center',
    border: '1px solid transparent',
    boxShadow: disabled ? undefined : pressedDown ? elevationPressed : elevationResting,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    textAlign: 'center',
    transition: `box-shadow 100ms ease-in`,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    ':hover,:focus,:active': {
      boxShadow: disabled ? undefined : pressedDown ? elevationPressed : elevationHovered,
      outline: pressedDown && 0
    }
  }
})

















const BaseButton = forwardRef((props, ref) => {
  const {
    onMouseDown,
    onMouseLeave,
    'aria-label': ariaLabel,
    size = 'small',
    children,
    className,
    disabled,
    elevationHovered,
    elevationResting,
    elevationPressed,
    onClick,
    onMouseEnter,
    style,
    waiting,
    dataCy
  } = props
  const hasDisabledStyles = !!(disabled || waiting)

  const [pressedDown, setPressedDown] = useState(false)

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      setPressedDown(true)
    }
    onMouseDown && onMouseDown(e)
  }

  const handleMouseUp = (e) => {
    setPressedDown(false)
    // We don’t want 'focus' styles to linger after the click (TA)
    // wait till next tick because other components might need to use the button as the relativeTarget when they get blurred
    // pull the target out of the event so react can recycle the event
    const {currentTarget} = e
    setTimeout(() => (currentTarget ).blur())
  }

  const handleMouseLeave = (e) => {
    setPressedDown(false)
    onMouseLeave && onMouseLeave(e)
  }

  // spread props to allow for html attributes like type when needed
  return (
    React.createElement(ButtonRoot, {
      ...props,
      'data-cy': dataCy,
      'aria-label': ariaLabel,
      className: className,
      disabled: hasDisabledStyles,
      elevationHovered: elevationHovered,
      elevationResting: elevationResting,
      elevationPressed: elevationPressed,
      ref: ref,
      onClick: onClick,
      onMouseDown: handleMouseDown,
      onMouseUp: handleMouseUp,
      onMouseEnter: onMouseEnter,
      onMouseLeave: handleMouseLeave,
      pressedDown: !hasDisabledStyles && pressedDown,
      size: size,
      style: style,
      waiting: waiting,}

      , children
    )
  )
})

export default BaseButton
