import styled from '@emotion/styled'
import React, {forwardRef,} from 'react'
import useModal from '~/hooks/useModal'
import DialogContent from './DialogContent'
import DialogTitle from './DialogTitle'
import MenuContents, {} from './MenuContents'
import PrimaryButton from './PrimaryButton'
import ReportErrorFeedback from './ReportErrorFeedback'






const ErrorBlock = styled(MenuContents)({
  background: '#fff',
  padding: 16
})

const Button = styled(PrimaryButton)({
  marginTop: 8
})

const ModalError = forwardRef((props, ref) => {
  const {error, eventId, ...blockProps} = props
  const {modalPortal, openPortal, closePortal} = useModal()
  return (
    React.createElement(ErrorBlock, { ...blockProps, ref: ref,}
      , React.createElement(DialogTitle, null, "You found a bug!"   )
      , React.createElement(DialogContent, null
        , 'We’ve alerted the developers. Try refreshing the page'
        , React.createElement(Button, { onClick: openPortal,}, "Report Feedback" )
        , modalPortal(React.createElement(ReportErrorFeedback, { closePortal: closePortal, eventId: eventId,} ))
      )
    )
  )
})

export default ModalError
