import styled from '@emotion/styled'
import React, {forwardRef, useEffect} from 'react'

import useTimeout from '../../hooks/useTimeout'
import Spinner from '../../modules/spinner/components/Spinner/Spinner'
import {PALETTE} from '../../styles/paletteV3'
import {LoaderSize, Times} from '../../types/constEnums'






const LoadingWrapper = styled('div')(
  ({height = 'fill-available', width = 'fill-available'}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height,
    width
  })
)










// the ref isn't currenty used, but the Menu component likes to pass along a ref to figure out if the child is an item
const LoadingComponent = forwardRef((props, ref) => {
  const {
    delay,
    height,
    loadingDelayRef,
    width,
    spinnerSize = LoaderSize.MAIN,
    showAfter = Times.HUMAN_ADDICTION_THRESH
  } = props
  const minDelay = useTimeout(showAfter)
  const timedOut = useTimeout(Times.MAX_WAIT_TIME)
  useEffect(() => {
    if (loadingDelayRef) {
      loadingDelayRef.current.start = Date.now()
    }
    const loadingDelay = loadingDelayRef && loadingDelayRef.current
    return () => {
      if (loadingDelay) {
        loadingDelay.stop = Date.now()
        loadingDelay.forceUpdate()
      }
    }
  }, [loadingDelayRef])
  if (showAfter && !minDelay) return null
  return (
    React.createElement(LoadingWrapper, { ref: ref, height: height, width: width,}
      , React.createElement(Spinner, {
        delay: delay,
        fill: timedOut ? PALETTE.TOMATO_500 : PALETTE.AQUA_400,
        width: spinnerSize,}
      )
    )
  )
})

export default LoadingComponent
